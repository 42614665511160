.navbar {
    position: fixed;
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 40px); 
    top: 0;
    z-index: 1000; 
    background-color: transparent; 
    padding: 0 20px; 
}

.left-section {
    display: flex;
    align-items: center;
}


.menu-button {
    margin-right: 20px; /* adjusted the margin */
}

.site-layout-content {
    padding-top: 60px; 
}

.logo {
    color: black;
    text-decoration: none;
}

.login-button {
    margin-left: 10px;
    text-decoration: none;
    color: black;
}

.navbar-content {
    display: flex;
    align-items: center;
}

.signup-button {
    margin-left: 20px;
    color: white;
    text-decoration: none;
    background-color: blue;
    padding: 10px 20px;
    border-radius: 25px;
}

.signup-button:hover {
    background-color: darkblue;
}

.submit-question-link {
    margin-right: 20px;
    color: black;
    text-decoration: none;
}

.submit-question-link:hover {
    text-decoration: underline;
}

.edit-question-link {
    margin-right: 20px; /* Added this property */
    color: black;
    text-decoration: none;
}

.edit-question-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .study-guide-logo {
        margin-left: 30px;
    }
}
