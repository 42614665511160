.ant-layout, .ant-layout-sider {
    min-height: 100vh !important;
  }

  .ant-layout-sider {
    background: #fff !important; /* Change #fff to any color you prefer */
  }
  
  .ant-layout-sider .ant-menu {
    background: #fff !important; /* This is for the menu items inside the sider */
  }

.menu-button {
    position: fixed;
    z-index: 2;
    border: none;
    background: none;
    color: #000;
    font-size: 24px;
    padding: 15px;
    display: none;  /* Initially set to none */
  }

  .menu-button {
    top: 10px;   /* Adjust the position */
    left: 10px;  /* Adjust the position */
    z-index: 3;  /* Adjust if necessary to ensure the button is above other elements */
    padding: 10px; /* Adjust padding as needed */
  }
  
  
  .overlay {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .site-layout-background {
    z-index: 2;
    position: fixed;  
    height: 100% !important;
    width: 200px;  /* Add width */
    overflow-y: auto;  
    transition: all 0.3s;
    left: -200px;  /* Updated from translateX */
}

.site-layout-background.active {
    left: 0;  /* Updated from translateX */
}

@media (min-width: 769px) {
    .site-layout-background {
      position: static;  /* Updated to static */
      left: 0 !important;  /* Ensure the sider is visible */
    }
  
    .overlay {
      display: none !important;  /* Hide the overlay on desktop */
    }
  
    .menu-button {
      display: none !important;  /* Hide the menu button on desktop */
    }
  }
  
  /* On small screens, hide the menu-button and set the sider off the screen */
  @media(max-width: 768px) {

    .site-layout-content {
        padding: 0; /* Or any suitable value */
    }

    .site-layout-content p, .site-layout-content h1 {
        margin: 0 auto; /* Centered */
        width: auto;
    }


    .site-layout-content-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .site-layout-content-mobile p, .site-layout-content-mobile h1 {
        width: 100%;
    }

    .menu-button {
      display: block; /* Show the menu button */
    }
  
    .site-layout-background {
      transform: translateX(-100%);  /* Hide the sider off the screen */
    }
  
    .site-layout-background.active {
      transform: translateX(0);  /* Show the sider when active */
    }
  
    .site-layout-content {
      width: 100%;  /* Make the content take full width */
    }
  }
  