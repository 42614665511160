body, html {
    margin: 0; /* Remove default margin */
    padding: 0; /* Remove default padding */
    font-family: 'Inter', sans-serif;
}

body, #root {
    background-color: white;
}

.signup-container {
    min-height: 80vh; /* Minimum height of the viewport */
    display: flex;
    justify-content: center;
    align-items: center; /* Align vertically */
}

.login-container {
    min-height: 80vh; /* Minimum height of the viewport */
    display: flex;
    justify-content: center;
    align-items: center; /* Align vertically */
}

.quiz-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 65vh;
}

.question-container {
    width: 100%;
    max-width: 800px;
}

.question-count {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    z-index: 1000;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}
body {
    background-color: #fff;
}


.results-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.questions-results {
    width: 100%;
    max-width: 800px;
}

.timer-container {
    position: absolute;
    top: 0;
    right: 0;
    padding: 30px;
    z-index: 1000;
    text-align: left;
}

.timer-container .ant-statistic-content-value {
    font-size: 16px;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.analytics-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.analytics-container .ant-card {
    margin-top: 20px;
}

.question-content {
    max-height: 200px;  /* Adjust the height as needed */
    overflow-y: auto;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #f0f0f0;
    border-radius: 4px;
}




/* Responsive styles */
@media (max-width: 600px) {
    .question-container {
        padding: 10px;
    }

    .question-container h2,
    .question-container h3,
    .results-container h1,
    .results-container h2 {
        font-size: 1em;
    }
}